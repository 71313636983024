import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import "../assets/css/global.css"
import Instagram from "../assets/images/instagram.svg"
import Facebook from "../assets/images/facebook.svg"
import LinkedIn from "../assets/images/linkedin.svg"
import Twitter from "../assets/images/twitter.svg"
import LoadMore from "../assets/images/Load-more-arrow.svg"
import { StructuredText } from "react-datocms";
import { Helmet } from 'react-helmet'
import Seo from "../components/Seo"

const FilmTemplate = ({data}) => {
  const {title, storyline, awardsPrizes, cast, crew, technicalSpecs, universityCollege, videoUrl, genre, director, length, instagramLink, facebookLink, twitterLink, linkedInLink, qAContainer} = data.datoCmsVideo

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>{title}</title>
      </Helmet>
      <Seo title={title} description={storyline} image="../assets/images/Home-Hero.png" />
      <main >
        <div className="recipe-page">
          {/* <img className="hero-img pb-50" style={{margin: "auto"}} src={`https://img.youtube.com/vi/${videoUrl.providerUid}/maxresdefault.jpg`}/> */}
          <div className="video-container" style={{maxWidth: "100%", margin: "auto"}}>
            {/* <iframe className="video" width="80%" height="100%" className="pb-50" src={`https://www.youtube.com/embed/${videoUrl.providerUid}?rel=0;color=white;modestbranding=1`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allow="fullscreen"></iframe> */}
            <iframe title="Film Player" className="pb-50" src={`https://player.vimeo.com/video/${videoUrl.providerUid}`} width="640" height="360" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture"></iframe>
          </div>
          <div className="">
            <div className="hero-text">
              <p style={{fontWeight: "800"}}>{genre} / {director} / {length} minutes</p>
              <h1 style={{color: "#232324"}}>{title}</h1>
              <p style={{marginBottom: "50px"}} className="lowercase">{universityCollege}</p>
            </div>
          </div>
          <div className="container">
            <div className="grid-cols-4 grid-cols-1">
              <div className="col-span-1">
                <p style={{fontWeight: "bold"}}>Storyline</p>
              </div>
              <div className="col-span-3">
                <p style={{whiteSpace: "pre-line"}}>{storyline}</p>
              </div>
            </div>
            <hr style={{width: "100%", backgroundColor: "#B2B8BF", height: "3px", display: "block"}}/>
            {awardsPrizes ? <div className="grid-cols-4 grid-cols-1 pt-50">
              <div className="col-span-1">
                <p style={{fontWeight: "bold"}}>Awards &amp; Prizes</p>
              </div>
              <div className="col-span-3">
                <p style={{whiteSpace: "pre-line"}}>{awardsPrizes}</p>
              </div>
            <hr style={{width: "100%", backgroundColor: "#B2B8BF", height: "3px", display: "block"}}/>
            </div> : null}
            <div className="grid-cols-4 grid-cols-1 pt-50">
              <div className="col-span-1">
                <p style={{fontWeight: "bold"}}>Cast</p>
              </div>
              <div className="col-span-3">
                <p style={{whiteSpace: "pre-line"}}>{cast}</p>
              </div>
            </div>
            <hr style={{width: "100%", backgroundColor: "#B2B8BF", height: "3px", display: "block"}}/>
            <div className="grid-cols-4 grid-cols-1 pt-50">
              <div className="col-span-1">
                <p style={{fontWeight: "bold"}}>Crew</p>
              </div>
              <div className="col-span-3">
                <p style={{whiteSpace: "pre-line"}}>{crew}</p>
              </div>
            </div>
            <hr style={{width: "100%", backgroundColor: "#B2B8BF", height: "3px", display: "block"}}/>
            <div className="grid-cols-4 grid-cols-1 pt-50">
              <div className="col-span-1">
                <p style={{fontWeight: "bold"}}>Technical Specs</p>
              </div>
              <div className="col-span-3">
                <p style={{whiteSpace: "pre-line"}}>{technicalSpecs}</p>
              </div>
            </div>
            <hr style={{width: "100%", backgroundColor: "#B2B8BF", height: "3px", display: "block"}}/>
            <div className="col-span-3 margin-auto pt-50 icon-center">
            {instagramLink ?<a href={ instagramLink } target="_blank" rel="noreferrer">
                  <img
                    src={Instagram}
                    alt="Instagram icon"
                    className="hero-img"
                    placeholder="tracedSVG"
                    style={{maxWidth: "10%", display: "inline-block", fontSize: "10px", paddingBottom: "15px", paddingRight: "15px"}}
                  />
            </a> : null }
            {facebookLink ? 
                <a href={ facebookLink } target="_blank" rel="noreferrer">
                  <img
                    src={Facebook}
                    alt="Facebook icon"
                    className="hero-img"
                    placeholder="tracedSVG"
                    style={{maxWidth: "10%", display: "inline-block", fontSize: "10px", paddingBottom: "15px", paddingRight: "15px"}}
                  />
                </a> : null }
                {linkedInLink ? <a href={ linkedInLink } target="_blank" rel="noreferrer">
                  <img
                    src={LinkedIn}
                    alt="LinkedIn icon"
                    className="hero-img"
                    placeholder="tracedSVG"
                    style={{maxWidth: "10%", display: "inline-block", fontSize: "10px", paddingBottom: "15px", paddingRight: "15px"}}
                  />
                </a> : null }
                {twitterLink ? <a href={ twitterLink } target="_blank" rel="noreferrer">
                  <img
                    src={Twitter}
                    alt="Twitter icon"
                    className="hero-img"
                    placeholder="tracedSVG"
                    style={{maxWidth: "10%", display: "inline-block", fontSize: "10px", paddingBottom: "15px"}}
                  />
                </a> : null }
              </div>
          </div>
        </div>
        <div className="container">
        {qAContainer.map(qa => {
             const { question, answer } = qa
             return (
               <div>
               <p className="questions">Q. {question}</p>
               <div className="formatted-content">
                <StructuredText data={answer} />
               </div>
               </div>
             )
           }) }
        </div>
      </main>
      <div className="container">
        <div className="grid-cols-3">
          <p className="col-span-3" style={{textAlign: "center", marginBottom: "4rem", display: "none"}}>No more results</p>
            <a style={{gridColumnStart: "6"}} href="#top">
              <img
                src={LoadMore}
                alt="flour and eggs"
                placeholder="tracedSVG"
                layout="constrained"
              />
            </a>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
query getSingleFilm($title: String) {
  datoCmsVideo(title: {eq: $title}, videoUrl: {thumbnailUrl: {}}) {
    qAContainer {
      question
      answer {
        value
      }
    }
    title
    storyline
    awardsPrizes
    cast
    crew
    technicalSpecs
    universityCollege
    videoUrl {
      url
      thumbnailUrl
      providerUid
    }
    genre
    director
    length

  }
}
`

export default FilmTemplate